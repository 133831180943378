import ComponentStore from 'components/data/ComponentStore';
import User from 'components/data/User';
import { Brick } from '../types/brick.type';
import { BricksComponentStore, CSBrickPublishJob, CSPublishExecutionPanel, CSPublishExecutionPanelJob, PublishJobs } from '../types/bricksComponentStore.type';
import { JobStatus, OutputAction } from '../types/publish.type';
import { BrickPublishJob } from '../types/brick-publish.type';
import BrickDataService from '../services/brick-data.service';
import { BrickPublishJobData } from '../hooks/useBricksPublish';
import BricksComponentStoreHelper from './bricks-component-store.helper';
import PublishMessagesService from '../services/publish-messages.service';
import PublishService from '../services/publish.service';
import DownloadHelpers from './download.helpers';

class PublishHelpers {
    static publishBricks = async (bricks: Brick[]): Promise<(BrickPublishJob & { brickId: string })[] | undefined> => {
        const result = await PublishService.publishBricks(bricks);

        if (!result) return;

        const { jobId, publishId } = result;

        const publishJob = PublishHelpers.handleAddBricksPublishJob(bricks, jobId, publishId);
        PublishHelpers.handleBrickPublishStatusUpdate(bricks, publishId);

        const subscriptionId = PublishMessagesService.subscribe(publishId);

        const backendSubscriptions = ComponentStore.get('Bricks')?.backendSubscriptions || [];

        ComponentStore.setModel('Bricks', 'backendSubscriptions', [
            ...backendSubscriptions,
            {
                publishId,
                subscriptionId
            }
        ]);

        return bricks.map((brick) => {
            return {
                brickId: brick.id,
                ...publishJob
            };
        });
    };

    /**
     * Handles the tasks that need to be done after a publish status is changed
     * @param bricks The bricks to update
     * @param status The new status for these bricks
     * @returns
     */
    static handleBrickPublishStatusUpdate = (bricks: Brick | Brick[], publishId: string): void => {
        if (!bricks) return;

        if (!Array.isArray(bricks)) bricks = [bricks];
        const brickIds = bricks.map((brick) => brick.id);

        PublishHelpers.updateExecutionPanelBricks(brickIds, 'add', publishId);
    };

    static handleAddBricksPublishJob = (
        bricks: Brick | Brick[],
        jobId: string,
        publishId: string,
        publishProfile = 'default',
        outputAction: OutputAction = 'publish'
    ): BrickPublishJob => {
        if (!Array.isArray(bricks)) bricks = [bricks];

        const createdAt = new Date().toISOString();
        const publishJob: BrickPublishJob = {
            createdAt,
            status: JobStatus.WAITING,
            jobToken: jobId,
            publishId,
            failedTasks: 0,
            finishedTasks: 0,
            totalTasks: 0,
            createdBy: Number(User.get('id')),
            outputAction
        };

        bricks.forEach((brick) => {
            const publishJobs = brick.publish && brick.publish[publishProfile] ? brick.publish[publishProfile] : [];
            publishJobs.unshift(publishJob);
            BricksComponentStoreHelper.setBrickModel(brick.id, `publish.${publishProfile}`, publishJobs);
        });

        return publishJob;
    };

    /**
     * Updates the bricks in the execution panel. Get the current array of brick ids and adds the new brick ids to it.
     * @param bricks The bricks to add to the execution panel
     */
    static updateExecutionPanelBricks = (brickIds: string[] = [], action: 'add' | 'remove', publishId?: string): void => {
        const currentJobs = (ComponentStore.get('Bricks') as BricksComponentStore).publishExecutionPanel.jobs || [];

        if (action === 'add') {
            if (!publishId) return;
            const executionPanelJobs: CSPublishExecutionPanelJob[] = [...currentJobs, { publishId, brickIds }];
            const publishExecutionPanel: CSPublishExecutionPanel = { jobs: executionPanelJobs, open: true };
            ComponentStore.setModel('Bricks', 'publishExecutionPanel', publishExecutionPanel);
        } else {
            // Remove jobs containing any of the bricks to be deleted
            const updatedJobs = currentJobs.filter(({ brickIds: currentBrickIds }) => !currentBrickIds.some((brickId) => brickIds.includes(brickId)));

            const publishExecutionPanel: CSPublishExecutionPanel = { jobs: updatedJobs, open: updatedJobs.length > 0 };
            ComponentStore.setModel('Bricks', 'publishExecutionPanel', publishExecutionPanel);
        }
    };

    static getPublishStatus(
        publishJobs?: BrickPublishJobData[],
        validationErrors?: Array<unknown>
    ): 'readyToPublish' | 'success' | 'publishing' | 'error' | 'info' | 'blocking' | 'canceled' {
        // if all the publisjobs are finished return success
        // if all the publisjobs are waiting return publishing
        // if all the publisjobs are working return publishing
        // if all the publisjobs are failed return error
        // if there is a mix of statuses return info;

        const jobStatusses = publishJobs?.map((job) => job.status);
        const uniqueStatuses = Array.from(new Set(jobStatusses));
        const isPublishDownloading = DownloadHelpers.isPublishDownloading(publishJobs || []);

        const status = (() => {
            if (!uniqueStatuses.length) return;
            // Check if all the publish jobs are fully finished
            if (isPublishDownloading) return 'publishing';
            if (uniqueStatuses.includes(JobStatus.WAITING) || uniqueStatuses.includes(JobStatus.WORKING)) return 'publishing';
            if (uniqueStatuses.length === 1) {
                if (uniqueStatuses[0] === JobStatus.FINISHED) return 'success';
                return 'error';
            }
            return 'info';
        })();

        if (status) return status;

        if (validationErrors && validationErrors.length > 0) return 'blocking';
        if (!publishJobs) return 'readyToPublish';
        if (publishJobs.length === 0) return 'readyToPublish';
        if (publishJobs.some((job) => job.status === JobStatus.CANCELED)) return 'canceled';

        return 'info';
    }

    static groupBricksByRunningPublishId = (bricks: Brick[]): CSPublishExecutionPanelJob[] => {
        const groupedBricks: Record<string, string[]> = {};

        // Get running jobIds for the bricks
        const runningJobs = bricks.reduce((acc, brick) => {
            const runningJobs = brick.publish?.default.filter((job) => job.status === JobStatus.WORKING || job.status === JobStatus.WAITING);

            runningJobs?.forEach((job) => {
                if (!acc.includes(job.publishId)) acc.push(job.publishId);
            });

            return acc;
        }, [] as string[]);

        // Loop through the bricks to check if they have the running job
        bricks.forEach((brick) => {
            brick.publish?.default?.forEach((job) => {
                const publishId = job.publishId;
                if (runningJobs.includes(publishId)) {
                    if (!groupedBricks[publishId]) {
                        groupedBricks[publishId] = [];
                    }
                    groupedBricks[publishId].push(brick.id);
                }
            });
        });

        return Object.entries(groupedBricks).map(([publishId, brickIds]) => ({
            publishId,
            brickIds
        }));
    };

    /**
     * Check if any bricks are job which is publishing
     * @param bricks
     */
    static checkBricksPublishStatus(): void {
        BrickDataService.getPublishingBricks().then((bricks: Brick[] | undefined) => {
            if (!bricks || !bricks.length) return;

            const publishIds = (() => {
                const ids = new Set<string>();

                bricks.forEach((brick) => {
                    brick.publish?.default?.forEach((job) => {
                        // check if the job is still running
                        if (job.status === JobStatus.WORKING || job.status === JobStatus.WAITING) ids.add(job.publishId);
                    });
                });
                return ids;
            })();

            const newlyBackenedSubscriptions = publishIds.values().map((publishId) => {
                return {
                    publishId: publishId,
                    subscriptionId: PublishMessagesService.subscribe(publishId)
                };
            });

            const backendSubscriptions = ComponentStore.get('Bricks')?.backendSubscriptions || [];
            ComponentStore.setModel('Bricks', 'backendSubscriptions', [...backendSubscriptions, ...newlyBackenedSubscriptions]);

            const runningJobs = this.groupBricksByRunningPublishId(bricks);

            runningJobs.forEach(({ brickIds, publishId }) => this.updateExecutionPanelBricks(brickIds, 'add', publishId));
        });
    }

    /**
     * Filter out the download jobs that are not from the user
     */
    static getJobsFromUser = (jobs: CSBrickPublishJob[], publish: PublishJobs): CSBrickPublishJob[] => {
        return jobs.filter((job: CSBrickPublishJob) => {
            const brickJobs = Object.keys(job.bricks).map((brickId) => brickId);
            const brickId = brickJobs[0];
            const outputAction = job.bricks[brickId].outputAction;
            const createdBy = job.bricks[brickId]?.createdBy || publish[job.publishId]?.bricks[brickId]?.createdBy;
            const isJobFromUser = createdBy === Number(User.get('id'));

            // Filter out the download jobs that are not from the user
            if (outputAction === 'download' && !isJobFromUser) return false;
            return true;
        });
    };
}

export default PublishHelpers;
