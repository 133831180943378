import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BrickSlidePanelOptions } from '../helpers/slide-panel.helpers';
import { CompactViewDetailOptions } from '../helpers/compact-view.helpers';

const useBricksNavigation = (): {
    activeItemId: string | undefined;
    locationState: BrickSlidePanelOptions | CompactViewDetailOptions | undefined;
    campaignId: string;
    bricksPath: string;
    rootPath: string;
    basePath: string;
    navigateToItem: (itemId: string, options?: BrickSlidePanelOptions | CompactViewDetailOptions) => void;
    closeItem: (options?: BrickSlidePanelOptions) => void;
} => {
    const history = useHistory();
    const { pathname, state } = useLocation();

    const params = useParams<Record<string, string>>();
    const { id: campaignId, path: bricksPath, path2: activeItemId } = params;

    const rootPath = pathname.split('/').slice(1, 4).join('/');

    // Calculate the path with specified params removed.
    const removeParamsFromPath = (paramsToRemove: string[]) => {
        return Object.values(paramsToRemove).reduce((pathname: string, param) => pathname.replace('/' + param, ''), pathname);
    };

    // Calculate the path without it's params.
    const basePath = removeParamsFromPath(Object.values(params));

    // Navigate to a specific Brick item.
    const navigateToItem = (itemId: string, options?: BrickSlidePanelOptions | CompactViewDetailOptions) => {
        const path = `/${rootPath}/${itemId}`;
        history.push(path, options);
    };

    const closeItem = (options?: BrickSlidePanelOptions) => {
        const path = `/${rootPath}`;
        history.push(path, options);
    };

    return {
        activeItemId,
        locationState: state,
        campaignId,
        bricksPath,
        rootPath,
        basePath,
        navigateToItem,
        closeItem
    };
};

export { useBricksNavigation };
