import merge from 'lodash/merge';
import set from 'lodash/set';
import { v4 as uuidv4 } from 'uuid';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import { CreativeV2CustomUpload } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import ComponentStore from 'components/data/ComponentStore';
import { AddBrickPayload, Brick } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { MODEL_ASSET_VALIDATION_RESULTS, MODEL_CREATIVES, MODEL_UPDATED_BRICKS } from '../constants';
import { AssetHelpers } from './asset.helpers';
import { BricksComponentStore } from '../types/bricksComponentStore.type';

class AssetSetHelper {
    /**
     * Add a new brick to the asset set with the provided creatives and title.
     * @param brickTitle The title of the new brick.
     * @param creatives Creatives to be added to the new brick.
     * @param parentBrickId The ID of the parent brick which is the asset set brick.
     */
    static async addNewBrickToAssetSet(brickTitle: string, creatives: CreativeV2CustomUpload[], parentBrickId?: string): Promise<Brick[] | undefined> {
        let newBrick: AddBrickPayload = {
            campaignId: EditorData.getId(),
            subType: 'single_asset',
            parentId: parentBrickId,
            title: brickTitle
        };

        const defaultData = BrickHelpers.getDefaultBrickData('single_asset'); // Get the default data for the new brick.
        newBrick = merge(newBrick, defaultData); // Merge the default data with the new brick.
        set(newBrick, MODEL_CREATIVES, creatives); // Update the the brick with the new creatives.

        const addedBricks = await BrickHelpers.addBricks([newBrick]); // Add the new brick to the editor.

        return addedBricks; // Return the added bricks.
    }

    /**
     * Check if the uploaded asset matches any existing brick preset.
     * If the asset matches with an existing brick preset, update the existing brick with the asset metadata.
     * @param creativeCustomUpload The creative data of the asset.
     * @param children The children of the asset set brick.
     * @returns True if the asset is matched with an existing brick, false otherwise.
     */
    static handleBrickPresetMatching(creativeCustomUpload: CreativeV2CustomUpload, children?: Brick[]): boolean {
        if (!children) return false;

        const existingBrickWithMatchingPreset = AssetHelpers.findValidPresetBrick(children, creativeCustomUpload.data); // Find the existing brick with matching preset.

        if (existingBrickWithMatchingPreset) {
            const { brick, validationResults } = existingBrickWithMatchingPreset;
            const brickPrefix = BrickHelpers.getBrickPrefix(brick.id);
            const brickIdPrefix = BrickHelpers.getBrickIdPrefix(brick.id);

            ComponentStore.setMultiModels('Bricks', [
                [`${MODEL_UPDATED_BRICKS}.${brickIdPrefix}`, brick.id],
                [`${brickPrefix}.${MODEL_CREATIVES}`, [creativeCustomUpload]],
                [`${brickPrefix}.${MODEL_ASSET_VALIDATION_RESULTS}`, validationResults]
            ]);
            return true; // Return true if the asset is matched with an existing brick.
        }

        return false; // Return false if the asset is not matched with an existing brick.
    }

    /**
     * Handle the mutation for the uploaded asset.
     * @param sourceData Source data coming from the FileUpload component.
     * @param dataType Data type of the source data.
     * @param brick The asset set brick to which the new brick will be added.
     * @param onValidationStart Callback function to be called when the validation starts.
     * @param onValidationComplete Callback function to be called when the validation is completed.
     */
    static async handleMutation(sourceData: SourceData, brick?: Brick, dataType?: string): Promise<Brick[] | undefined> {
        try {
            if (dataType === 'sourceData') return; // If the data type is source data, return.
            if (!sourceData.extension) throw new Error(); // If the file extension is not provided, throw an error.

            const assetMetadata = await AssetHelpers.getAssetMetadataBySourceData(sourceData);

            if (!assetMetadata) throw new Error(); // If the asset metadata is not provided, throw an error.

            const creativeCustomUpload: CreativeV2CustomUpload = {
                id: uuidv4(),
                type: 'customUpload',
                title: sourceData.title ?? '',
                data: assetMetadata
            };

            const bricksComponentStore: BricksComponentStore | undefined = ComponentStore.get('Bricks');
            const bricks = bricksComponentStore?.bricks;
            const brickChildren = bricks && brick ? BrickHelpers.getChildrenFromBricks(bricks, brick.id) : [];
            const isAssetPresetMatchFound = AssetSetHelper.handleBrickPresetMatching(creativeCustomUpload, brickChildren); // Check if the asset is matched with an existing brick.

            if (isAssetPresetMatchFound) return; // If the asset is matched with an existing brick, return.

            const addedBricks = await AssetSetHelper.addNewBrickToAssetSet(sourceData.title ?? '', [creativeCustomUpload], brick?.id);
            return addedBricks; // Return the added bricks.
        } catch (error) {
            SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
        }
    }
}

export default AssetSetHelper;
