import BrickHelpers from '../helpers/brick.helpers';
import {
    Brick,
    BrickAssetSet,
    BrickChannel,
    BrickChannelValues,
    BrickFeedCustom,
    BrickMetaAd,
    BrickMetaAdSet,
    BrickMetaCampaign,
    BrickSetup,
    BrickSingleAsset,
    BrickSocialPlaformValues,
    BrickSocialPlatform,
    BrickSubType,
    BrickSubTypeValues,
    CustomRowType
} from '../types/brick.type';
import { BrickTab, UnifiedTab } from '../types/bricksComponentStore.type';

type BrickFeedTypeMap = {
    feed_custom: BrickFeedCustom;
};

type BrickSubTypeMap = {
    asset_set: BrickAssetSet;
    single_asset: BrickSingleAsset;
    meta_ad: BrickMetaAd;
    meta_adset: BrickMetaAdSet;
    meta_campaign: BrickMetaCampaign;
    feed_custom: BrickFeedCustom;
};

/**
 * Guards for the different brick types. We use this to make sure that a specific brick has its own data object.
 * @example (Guards.isBrickOfType(brick, 'asset_set')) // returns true if brick is an asset set and will give the brick type BrickAssetSet
 */
class Guards {
    static isBrickOfType<T extends keyof BrickSubTypeMap>(brick: Brick, subType: T): brick is BrickSubTypeMap[T] {
        return brick.subType === subType;
    }

    static isBrickTab = (tab: UnifiedTab): tab is BrickTab => {
        return 'brickTab' in tab && tab.brickTab;
    };

    static isFeedBrick<T extends keyof BrickFeedTypeMap>(brick: Brick): brick is BrickFeedTypeMap[T] {
        const brickSetup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');
        return !!brickSetup?.config?.masterBrick;
    }

    static isBrickSocialPlatform(value: string): value is BrickSocialPlatform {
        return value in BrickSocialPlaformValues;
    }

    static isBrickChannel(value: string): value is BrickChannel {
        return value in BrickChannelValues;
    }

    static isBrickSubType(value: string): value is BrickSubType {
        return value in BrickSubTypeValues;
    }

    static isCustomRowType = (value: string): value is CustomRowType => {
        return Object.values(CustomRowType).includes(value as CustomRowType);
    };
}

export default Guards;
