import ComponentStore from 'components/data/ComponentStore';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { BrickSetup, BrickTree, BrickType, Brick, CustomRowType } from '../types/brick.type';
import { BricksObject } from '../types/bricksComponentStore.type';
import BrickHelpers from './brick.helpers';
import { MODEL_BRICKS, MODEL_SETTINGS_FORMATS } from '../constants';
import CustomBrickHelpers from './custom-brick-helper';

class TreeHelpers {
    /**
     * This function is responsible in order to convert the bricks object into a tree structure.
     * @param bricksObject A list of all bricks in a form of an object.
     */
    static createTreeV2 = (bricksObject?: BricksObject): BrickTree[] => {
        // First pass: build all nodes
        const brickMap = new Map<string, BrickTree>();
        for (const id in bricksObject) {
            const brick = bricksObject[id];
            const treeNode: BrickTree = { id: brick.id, children: [], order: brick.order };
            const brickSetup = BrickHelpers.getBrickData<BrickSetup>(brick.subType, 'setup');

            if (brickSetup?.config?.masterBrick) {
                treeNode.children = [
                    {
                        id: brick.id + '-master',
                        children: []
                    },
                    {
                        id: brick.id + '-child',
                        children: []
                    }
                ];
            }

            brickMap.set(id, treeNode);
        }

        // Second pass: link parents and children
        const roots: BrickTree[] = [];
        for (const id in bricksObject) {
            const brick = bricksObject[id];
            const treeNode = brickMap.get(id);

            if (!treeNode) continue;

            if (brick.parentId) {
                const parentBrickId = BrickHelpers.getBrickIdPrefix(brick.parentId);
                let parentTreeNode = brickMap.get(parentBrickId);
                const masterBrick = BrickHelpers.getBrickById(brick.masterBrickId);

                if (!parentTreeNode && treeNode) {
                    roots.push(treeNode); // If the parent does not exist, the node is a root node, this is used when a partial tree is passed.
                    continue;
                }

                if (!parentTreeNode) parentTreeNode = { id: parentBrickId, children: [], order: brick.order };

                if (brick.type === BrickType.MASTER) {
                    // If brick is master add it in masters section of its parent
                    parentTreeNode.children[0].children.push(treeNode);

                    continue;
                } else if (brick.type === BrickType.MASTER_CHILD) {
                    // If brick is master child add it to its parent
                    parentTreeNode.children.push(treeNode);
                    continue;
                } else if (
                    masterBrick &&
                    masterBrick?.type === BrickType.MASTER &&
                    parentTreeNode.children.length >= 2 &&
                    parentTreeNode.children[1].id.includes('child') // Only add output bricks to the child section
                ) {
                    // If first layer child of a feed brick pagigate it

                    // Get parent brick
                    const parentBrick = BrickHelpers.getBrickById(parentBrickId);

                    // The first item represent the output section brick.
                    parentTreeNode.children[1].children.push(treeNode);

                    if (!parentBrick) continue;

                    // Check if the load more brick is already added.
                    const loadMoreBrickId = `${parentTreeNode.id}-${CustomRowType.LOAD_MORE}`; // Custom id for load more brick.
                    const isLoadMoreBrickAlreadyAdded = parentTreeNode.children[1].children.find((child) => child.id === loadMoreBrickId);
                    if (!isLoadMoreBrickAlreadyAdded) {
                        parentTreeNode.children[1].children.push({
                            id: loadMoreBrickId,
                            children: [],
                            parentId: parentTreeNode.id,
                            order: 9999 // This is needed in order to sort the load more brick at the end of the list.
                        });
                    }
                    continue;
                }

                if (parentTreeNode && treeNode) {
                    parentTreeNode.children.push(treeNode); // Add child to parent.
                }
            } else {
                if (treeNode) roots.push(treeNode); // If the brick does not have a parentId, it is a root node.
            }
        }

        // Return a new sorted tree
        return roots
            .map(TreeHelpers.sortChildren) // Recursively sort the children
            .sort((a, b) => (a.order ?? 1) - (b.order ?? 1)); // Sort the root nodes
    };

    /**
     * Sort the children of each node based on the order position and return a new sorted node
     */
    static sortChildren = (node: BrickTree): BrickTree => {
        const sortedChildren = node.children
            .map(TreeHelpers.sortChildren) // Recursively sort the children
            .sort((a, b) => (a.order ?? 1) - (b.order ?? 1));

        return { ...node, children: sortedChildren };
    };

    static deleteBrickCreativeFormats = (brick: Brick, activeFormats: string[]): void => {
        const brickIdPrefix = BrickHelpers.getBrickPrefix(brick.id);

        const modelToUpdate = activeFormats.map((format) => {
            const id = BrickHelpers.getCustomBrickId(brick.id, format);
            const brickIdPrefix = BrickHelpers.getBrickPrefix(id);
            return [brickIdPrefix];
        });
        // Remove the formats from the bricks, it's not needed anymore.
        modelToUpdate.push([`${brickIdPrefix}.${MODEL_SETTINGS_FORMATS}`]);

        ComponentStore.removeItems('Bricks', modelToUpdate);
    };

    static addBrickCreativeFormats = (activeFormats: string[], brick: Brick): void => {
        const modelToUpdate: unknown[][] = [];
        const formats = CreativeV2FormatHelpers.getFormats(brick?.data?.creatives?.[0]?.data?.templateIdentifier, activeFormats);

        // Create and add new custom bricks based on formats
        formats.forEach((format) => {
            const id = BrickHelpers.getCustomBrickId(brick.id, format.key);
            const brickIdPrefix = BrickHelpers.getBrickIdPrefix(id);
            const customBrick = BrickHelpers.createCustomBrick(brick, 'creative_format', format, { format: format.key });

            // Add the custom brick to the component store
            modelToUpdate.push([`${MODEL_BRICKS}.${brickIdPrefix}`, customBrick]);
        });

        ComponentStore.setMultiModels('Bricks', modelToUpdate);
    };

    /**
     * Add all creative formats based on the provided brick to the tree structure.
     */
    static handleCreativeFormats = (brick: Brick): void => {
        // Process creatives if they exist
        if (
            !brick.subType.startsWith('creative') ||
            (!brick.data?.creatives?.length && !brick.data?.settings?.formats) ||
            CustomBrickHelpers.isCustomBrick(brick)
        )
            return;

        // Remove the custom bricks if the creative is removed
        if (!brick.data?.creatives?.length && brick.data?.settings?.formats) {
            const activeFormats = brick.data.settings.formats;
            this.deleteBrickCreativeFormats(brick, activeFormats);
            return;
        }

        const creative = brick.data.creatives[0];
        let activeFormats: string[] = [];

        activeFormats = creative?.data?.settings?.activeFormats ?? [];

        brick.data.settings = { ...brick.data.settings, formats: activeFormats }; // Add active formats to the brick settings, this is used to track the active formats to publish.

        this.addBrickCreativeFormats(activeFormats, brick);
    };
}

export default TreeHelpers;
