import merge from 'lodash/merge';
import { get } from 'lodash';
import Format from 'types/format.type';
import { EventEmitterTypes } from 'types/event-emitter.type';
import {
    Brick,
    BrickAssetSet,
    BrickDataInfo,
    AddBrickPayload,
    BrickSetup,
    BrickSetupTab,
    BrickSubType,
    CampaignLevel,
    BrickType
} from 'components/bricks/types/brick.type';
import BrickDataService from 'components/bricks/services/brick-data.service';
import EditorData from 'components/editor-data/EditorData';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import { BricksComponentStore, BricksObject } from 'components/bricks/types/bricksComponentStore.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Guards from 'components/bricks/guards';
import cloneDeep from 'helpers/cloneDeep';
import { RegexHelpers } from 'helpers/regex.helpers';
import { DynamicDataHelper } from 'components/input/DynamicData/helpers/dynamic-data-helper';
import Translation from 'components/data/Translation';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import Setup from '../components/bricks';
import getCustomerConfig from '../data/customerConfig';
import { Preset } from '../types/preset';
import { BRICK_ID_PREFIX, MODEL_BRICKS, MODEL_PRESETS, MODEL_TEMP_BRICK } from '../constants';
import BricksComponentStoreHelper from './bricks-component-store.helper';
import MultiInputWrapperHelpers from '../components/shared/components/bricks-multi-input-block-wrapper/helpers';
import BrickExpandedRowsHelper from './brick-expanded-rows.helper';
import BrickFeedHelpers from './brick-feed-helpers';
import ValidateHelpers from './validate.helpers';
import CustomBrickHelpers from './custom-brick-helper';
import PublishHelpers from './publish.helpers';
import { updateFilterSetup } from './brick-filters.helpers';

export type IAdditionalVars = {
    [Key in BrickSubType | 'brick' | 'datasetId' | 'parent']?: Brick | string | BrickParent;
};

export interface BrickParent extends Brick {
    parent?: BrickParent;
}

class BrickHelpers {
    /**
     * Get the brick from redux via its id
     * @param id The id of the brick
     */
    static getBrickById = (id: string | undefined): Brick | undefined => {
        const bricks = (ComponentStore.get('Bricks') as BricksComponentStore)?.bricks;
        if (!bricks) return;

        const isIdValid = id?.startsWith(BRICK_ID_PREFIX);

        if (!id || !bricks) return; // If id is not given or bricks are not loaded, return undefined.
        if (!isIdValid) id = this.getBrickIdPrefix(id); // Add prefix to id if it's not there.

        return cloneDeep(bricks[id]);
    };

    /**
     * This function gets the data from the setup file. The setup file contains the config of each brick
     * @example BrickHelpers.getBrickData('single_asset', 'settings') => Returns the settings file of a single_asset brick
     * @param brickSubType The brick type
     * @param dataType The data type of the brick
     * @returns
     */
    static getBrickData = <T>(subType: BrickSubType | undefined, dataType: BrickDataInfo): T => {
        if (!subType) return null as T;

        return Setup[subType]?.[dataType] as T;
    };

    /**
     * This functions returns a specific tab from a brick, which it gets from the brick's setup
     * @param subType The subType of the brick
     * @param tab The tab that you want to return (e.g. 'publish', 'settings', etc)
     */
    static getBrickSetupTab = (subType: BrickSubType, tab: string): BrickSetupTab | undefined => {
        const setup: BrickSetup = BrickHelpers.getBrickData(subType, 'setup');
        const brickSetupTab: BrickSetupTab | undefined = setup?.tabs?.find((brickSetupTab: BrickSetupTab) => brickSetupTab.key === tab);
        return brickSetupTab;
    };

    /**
     * This function looks for the parent of a brick with a specific brick type. This works for both children and subitems.
     * @example BrickHelpers.getBrickParent(brick, 'multiSocial_campaign') => Returns the parent of the brick with subType multiSocial_campaign
     * @param brick The brick to get the parent from
     * @param brickParentType The brick type of the parent
     * @returns Parent of brick with specific brick type
     */
    static getBrickParent = (brick: Brick | AddBrickPayload, brickParentType: string): Brick | undefined => {
        // Stop looking if the parent should be a multiSocial brick, but the brick is not a subItem
        if (
            brick.type !== BrickType.MAIN &&
            brick.type !== BrickType.CHILD &&
            ['multiSocial_campaign', 'multiSocial_adset', 'multiSocial_ad'].includes(brickParentType)
        )
            return;

        const parentBrick = BrickHelpers.getBrickById(brick.parentId) as Brick;

        if (!parentBrick) return;

        // If parent brick is the one that we are searching for
        if (parentBrick && parentBrick.subType === brickParentType) return parentBrick;

        // If the parent brick is multiSocial
        if (parentBrick.subItems && parentBrick.subItems.length) {
            // Try to find the parent brick in the subitems
            const foundParent = parentBrick.subItems.find((foundBrick) => foundBrick.subType === brickParentType);
            if (foundParent) return foundParent;
        }

        return BrickHelpers.getBrickParent(parentBrick, brickParentType);
    };

    /**
     * Determine the type of the brick based on its parent structure
     */
    private static determineBrickType(brick: AddBrickPayload, brickParent?: Brick, brickFeedParent?: Brick): BrickType {
        if (brickFeedParent) {
            return brickFeedParent.id === brickParent?.id ? BrickType.MASTER : BrickType.MASTER_CHILD;
        }
        return brick.parentId ? BrickType.CHILD : BrickType.MAIN;
    }

    /**
     * Generate the appropriate title for the new brick
     */
    private static generateBrickTitle(brick: AddBrickPayload, setup: BrickSetup, brickParent?: Brick): string {
        // If the brick has siblings with the same subType, we should add a number to the title
        const siblingsWithSameSubType = (() => {
            if (!brickParent) return 0;
            const siblings = this.getChildrenOfBrick(brickParent);
            return siblings.filter((sibling) => sibling.subType === brick.subType).length;
        })();

        const title = (() => {
            if (brick.title) return brick.title;
            if (siblingsWithSameSubType > 0 && setup.title) return `${setup.title} ${siblingsWithSameSubType + 1}`;
            return setup.title;
        })();

        return title;
    }

    // Prepare the brick for creation (e.g. by adding things like type, title, metadata, etc, if necesssary)
    private static prepareBrickForCreation(brick: AddBrickPayload): AddBrickPayload {
        const setup: BrickSetup = this.getBrickData(brick.subType, 'setup');
        const brickParent = BrickHelpers.getBrickById(brick.parentId);
        const brickFeedParent = BrickHelpers.getBrickParent(brick, 'feed_custom');

        return {
            campaignId: EditorData.getId(),
            type: BrickHelpers.determineBrickType(brick, brickParent, brickFeedParent),
            subType: brick.subType,
            title: BrickHelpers.generateBrickTitle(brick, setup, brickParent),
            parentId: brick.parentId,
            data: brick.data,
            metadata: brick.metadata
        };
    }

    /**
     * Add a new brick to the database and redux
     * @param brickData If brickData is given, we use brickData (for example, in the recursive addBrick function). Otherwise, we use the data from .newBrick in redux
     */
    static addBricks = async (bricksToAdd?: AddBrickPayload[]): Promise<Brick[] | undefined> => {
        // Make sure editordata is updated, so wait 100ms
        if (!bricksToAdd) {
            await new Promise((res) => setTimeout(res, 100));
        }

        // If an array of bricksToAdd is given, take that array. If not, Get the data that the editor saved in redux under 'newBrick'.
        // This is prefilled data that cannot be saved under an brick id, because the brick is not saved yet (e.g. in the briefing tab)
        const bricks = (() => {
            if (bricksToAdd && bricksToAdd.length) return bricksToAdd;
            const temporaryBrick = BrickHelpers.getTemporaryBrick();
            if (temporaryBrick) return [temporaryBrick];
        })();

        if (!bricks || !bricks.length) return;

        // Prepare the bricks for creation (e.g. by adding things like type, title, metadata, etc, if necesssary)
        const newBricks = bricks.map((brick) => BrickHelpers.prepareBrickForCreation(brick));

        // Save the brick to the database
        const saveBrickResponse = await BrickDataService.saveBricks(newBricks);

        updateFilterSetup();

        if (!saveBrickResponse || !saveBrickResponse.length) {
            SnackbarUtils.error('Something went wrong with saving the brick');
            return;
        }

        const addedBricks = BrickHelpers.setNewBricksToCS(saveBrickResponse);

        return addedBricks;
    };

    static setNewBricksToCS = (bricks: Brick[]): Brick[] => {
        const componentStoreBricks = bricks.map((addedBrick, index) => {
            const brick = bricks[index];

            // If it's an asset set, check for presets. If it has presets, create a single_asset brick for each preset
            if (Guards.isBrickOfType(brick, 'asset_set')) {
                BrickHelpers.addAdditionalBricksAssetSet(addedBrick, brick);
            }

            // If it's a multiSocial brick, also push the subItems
            if (addedBrick.subType?.includes('multiSocial')) {
                BrickHelpers.addAdditionalBricksMultiSocial(addedBrick);
            }

            const brickPrefix = BrickHelpers.getBrickPrefix(addedBrick.id);

            BrickExpandedRowsHelper.setExpandedBrick(true, addedBrick.parentId); // Expand brick by default if parentId exists

            // For a feed, we immediately show the master and output rows underneath, so we want to expand them by default
            if (addedBrick.subType === 'feed_custom') {
                BrickExpandedRowsHelper.setExpandedBrick(true, addedBrick.id);
                BrickExpandedRowsHelper.setExpandedBrick(true, `${addedBrick.id}-master`);
                BrickExpandedRowsHelper.setExpandedBrick(true, `${addedBrick.id}-child`);
            }

            ValidateHelpers.validate(addedBrick);

            return [brickPrefix, addedBrick];
        });

        EventEmitterHelpers.sent(EventEmitterTypes.BricksAddBrick, bricks[0]);

        const lastAddedBrickId = bricks[bricks.length - 1]?.id;

        const models = [[`${MODEL_TEMP_BRICK}`, undefined], ['lastAddedBrickId', lastAddedBrickId], ...componentStoreBricks];
        ComponentStore.setMultiModels('Bricks', models);

        return bricks;
    };

    /**
     * The brick asset set can have single_assets as its children. In the case of a briefing, we can give an
     * array of presets to the asset set. We need to create a single_asset brick for each preset.
     * If you're in the create view, you can upload assets. In that case, we need to create a single_asset brick for
     * each asset in the data.assets path
     * @param addedBrick The brick of the asset set that is added to the database, because we need its id
     * @param brick The original brick data that was added as a newBrick
     */
    private static addAdditionalBricksAssetSet = (addedBrick: Brick, brick?: BrickAssetSet) => {
        if (!brick || (!brick.data?.assets && !brick.data?.presets)) return;

        const singleAssets = (() => {
            if (brick.data.assets) return brick.data.assets;
            if (brick.data.presets) return brick.data.presets;
        })();

        if (!singleAssets) return;

        const newBricks: AddBrickPayload[] = singleAssets.map((singleAsset, index) => {
            const data = (() => {
                if (brick.data?.assets) return { asset: singleAsset };
                if (brick.data?.presets)
                    return {
                        uploadRestrictionType: 'restricted',
                        presets: [singleAsset]
                    };
            })();

            return {
                campaignId: EditorData.getId(),
                subType: 'single_asset',
                type: BrickType.CHILD,
                title: `Asset ${index + 1}`,
                parentId: addedBrick.id,
                data
            };
        });

        BrickHelpers.addBricks(newBricks);
    };

    /**
     * For multisocial bricks, we need to add additional bricks for each platform. Those additional bricks
     * will have the type 'subItem'
     * @param addedBrick
     */
    private static addAdditionalBricksMultiSocial = (addedBrick: Brick) => {
        const addBrickDialog = ComponentStore.get('Bricks').addBrickDialog;
        const selectedPlatforms: BrickSubType[] = [];
        const brickCampaignLevel: CampaignLevel = addedBrick.subType.split('_')[1] as CampaignLevel;

        const parent = BrickHelpers.getBrickById(addBrickDialog.parentId);

        // If a multiSocial has selectedPlatforms, it's a campaign and we should push multiple platforms
        if (addedBrick?.data?.selectedPlatforms) {
            addedBrick.data.selectedPlatforms.forEach((selectedPlatform) => {
                const platform: string = selectedPlatform.split('_')[0];
                selectedPlatforms.push(BrickHelpers.getPlatformEquivalent(platform, brickCampaignLevel));
            });
        } else {
            parent?.subItems?.forEach((subItem) => {
                const platform: string | undefined = subItem.subType?.split('_')[0];
                if (subItem.type && platform) selectedPlatforms.push(BrickHelpers.getPlatformEquivalent(platform, brickCampaignLevel));
            });
        }

        const newBricks: AddBrickPayload[] = selectedPlatforms.map((subType) => {
            return {
                campaignId: EditorData.getId(),
                subType,
                type: BrickType.MASTER,
                title: addedBrick.title,
                parentId: addedBrick.id,
                data: {}
            };
        });

        BrickHelpers.addBricks(newBricks);
    };

    /**
     * Not every subType is called campaign/adset, so we should return the equivalent subType name
     * @param platform The platform
     * @param brickCampaignLevel Brick type level
     */
    private static getPlatformEquivalent = (platform: string, brickCampaignLevel: CampaignLevel): BrickSubType => {
        const multiSocialSubTypeMapping = {
            campaign: {
                meta: 'campaign',
                tiktok: 'campaign',
                snapchat: 'campaign',
                pinterest: 'campaign',
                linkedin: 'campaignGroup'
            },
            adset: {
                meta: 'adset',
                tiktok: 'adgroup',
                snapchat: 'adsquad',
                pinterest: 'adgroup',
                linkedin: 'campaign'
            },
            ad: {
                meta: 'ad',
                tiktok: 'ad',
                snapchat: 'ad',
                pinterest: 'ad',
                linkedin: 'ad'
            }
        };

        const subTypeEquivalent = multiSocialSubTypeMapping[brickCampaignLevel][platform];

        return `${platform}_${subTypeEquivalent}` as BrickSubType;
    };

    /**
     * Loads more children for a feed brick
     * @param brick
     * @returns feed brick with new page and the children requested
     */
    static loadMoreBricks = async (brickId: string, offset = 0): Promise<void> => {
        const childrenObject: BricksObject | undefined = await BrickDataService.loadMoreChildrenOfBrick(brickId, offset);

        if (!childrenObject || !Object.keys(childrenObject).length)
            return ComponentStoreHelpers.setModel('Bricks', `pagination.${brickId}.maxResultsReached`, true);

        const brickIds = Object.keys(childrenObject);

        for (const id of brickIds) {
            const brick = childrenObject[id];
            ComponentStore.setModel('Bricks', `${MODEL_BRICKS}.${id}`, brick);
        }
    };

    // Duplicate the brick and its potential children
    static duplicateBrick = async (bricks: Brick[]): Promise<void> => {
        const brickStructure = this.getBrickStructure(bricks);
        const duplicatedBricks = await BrickDataService.duplicateBricks(brickStructure);

        if (duplicatedBricks) this.setNewBricksToCS(duplicatedBricks);
    };

    /**
     * Get the children of a brick
     * @param brick The brick to get the children from
     * @param excludeCustomBricks If true, exclude custom bricks from the children
     */
    static getChildrenOfBrick = (brick: Brick, excludeCustomBricks = false): Brick[] => {
        const parentId = brick.id;

        const { bricks }: BricksComponentStore = ComponentStore.get('Bricks');

        if (!bricks) return [];

        return Object.values(bricks).filter((childBrick) => {
            if (excludeCustomBricks && childBrick.isCustomBrick) return false;
            if (childBrick.type === BrickType.MASTER_CHILD || childBrick.type === BrickType.MASTER) return false;
            return childBrick.parentId === parentId;
        });
    };

    /**
     * This function is responsible to get a brick and its inner children based on the provided id.
     * @param bricks The bricks to search from.
     * @param brickId The main brick to search for.
     * @param brickInnerChildren This is needed for the recursive functionality of finding all the children and its sub children of a brick.
     */
    static getBrickAndInnerChildren = (bricks: BricksObject, brickId: Brick['id'], brickInnerChildren: string[] = []): string[] => {
        brickInnerChildren.push(brickId);
        const brickChildren = BrickHelpers.getChildrenFromBricks(bricks, brickId);

        // Recursively check the children of each child
        brickChildren.forEach((brick) => {
            this.getBrickAndInnerChildren(bricks, brick.id, brickInnerChildren);
        });

        return brickInnerChildren;
    };

    static getDefaultBrickData = (subType: BrickSubType): unknown => {
        // The default data from the brick setup
        let defaultData: unknown = BrickHelpers.getBrickData(subType, 'defaultData') ?? {};

        // Check if the brick has additional default data the is set up in the customer config
        const config = getCustomerConfig();

        config?.defaultData?.forEach((item) => {
            if (!MultiInputWrapperHelpers.validateInterfaceUpdate(item, subType)) return;
            if (item.data) {
                defaultData = merge(defaultData, item.data);
            }
        });

        return defaultData;
    };

    // Deleting brick by id
    static handleDeleteBrick = async (brickId: string): Promise<boolean> => {
        try {
            const brick = BrickHelpers.getBrickById(brickId);
            const isCustomBrick = brick && CustomBrickHelpers.isCustomBrick(brick);
            const formatsToDelete = brick?.data?.format ? [brick.data.format] : [];

            if (isCustomBrick) {
                await CustomBrickHelpers.handleCustomBrickDelete(brickId, formatsToDelete);
            } else {
                await BrickDataService.removeBrick(brickId); // Remove brick from the backend.
            }

            BrickExpandedRowsHelper.removeExpandedBrick(brickId); // Remove the expanded state of the brick from the local store.
            updateFilterSetup(); // Update filter setup
            return BricksComponentStoreHelper.deleteBrick(brickId); // Remove brick from the component store.
        } catch {
            return false;
        }
    };

    /**
     * Delete multiple bricks by their ids
     * @param brickIds A list of brick ids that must be deleted from the backend
     * @param allBrickIds A list of all brick ids that must be deleted from the component store. (temporary fix)
     */
    static handleDeleteMultipleBricks = async (brickIds: string[], allBrickIds?: string[]): Promise<boolean> => {
        try {
            const { customBricks, noneCustomBricks } = brickIds.reduce(
                (acc, brickId) => {
                    const brick = BrickHelpers.getBrickById(brickId);
                    if (!brick) return acc;
                    CustomBrickHelpers.isCustomBrick(brick) ? acc.customBricks.push(brickId) : acc.noneCustomBricks.push(brickId);
                    return acc;
                },
                { customBricks: [], noneCustomBricks: [] } as { customBricks: string[]; noneCustomBricks: string[] }
            );

            await CustomBrickHelpers.handleMultiCustomBricksDelete(customBricks); // Remove custom bricks.
            await BrickDataService.removeMultipleBricks(noneCustomBricks); // Remove bricks from the backend.
            updateFilterSetup(); // Update filter setup
            PublishHelpers.updateExecutionPanelBricks(brickIds, 'remove');
            ComponentStore.setModel('Bricks', 'checkedBricks', {}); // Clear the checked bricks from the component store.
            return BricksComponentStoreHelper.deleteMultipleBricks(allBrickIds ?? brickIds); // Remove bricks from the component store.
        } catch {
            return false;
        }
    };

    /**
     * Get the presets from the children of a parent brick.
     * @param parentBrick The parent brick.
     * @returns The presets from the children of the parent brick.
     */
    static getBrickPresetsFromChildren = (parentBrick: Brick): Preset[] => {
        const children = this.getChildrenOfBrick(parentBrick);

        const presets = children.map<Preset | undefined>((childBrick) => {
            const presets: Preset[] | undefined = get(childBrick, MODEL_PRESETS);
            return presets?.[0];
        }); // Get the presets from the children

        return presets.filter((preset) => preset !== undefined) as Preset[]; // Filter out undefined values;
    };

    /** Get the temporary brick from the component store. */
    static getTemporaryBrick = (): AddBrickPayload | undefined => {
        const bricksComponentStore: BricksComponentStore | undefined = ComponentStore.get('Bricks');
        return bricksComponentStore?.temporaryBrick;
    };

    /**
     * Get the children that belong to a parent brick.
     * @param bricks The bricks object.
     * @param parentId The parent id.
     * @returns The children of the parent brick.
     */
    static getChildrenFromBricks = (bricks: BricksObject, parentId: string): Brick[] => {
        return Object.values(bricks).filter((brick) => brick.parentId === parentId);
    };

    /**
     * Filter out the current bricks based on the given id.
     * @param bricks The bricks to filter.
     * @param brickId The brick id to filter out.
     * @returns The filtered bricks.
     */
    static filterBricksById = (bricks: BricksObject, brickId: string): BricksObject => {
        // Destructure the bricks object to separate the brick with the given id and the remaining bricks.
        // The brick with the given id is assigned to the throwaway variable '_', and the remaining bricks are gathered into 'filteredBricks'.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [brickId]: _, ...filteredBricks } = { ...bricks };

        return filteredBricks; // Return the remaining bricks.
    };

    /**
     * Get brick id prefix including the brick id.
     * @param brickId The brick id to add to the brick id prefix.
     * @returns The brick id with the prefix.
     * @example i_678a6dffe0e9bcfaf5bd0941
     */
    static getBrickIdPrefix = (brickId: string): string => {
        return `${BRICK_ID_PREFIX}${brickId}`;
    };

    /**
     * Get the prefix for the brick component store including the brick id.
     * @param brickId The brick id to add to the prefix.
     * @returns The prefix for the brick component store including the brick id
     * @example bricks.i_678a6dffe0e9bcfaf5bd0941
     */
    static getBrickPrefix = (brickId: string): string => {
        return `${MODEL_BRICKS}.${BRICK_ID_PREFIX}${brickId}`;
    };

    /**
     * This function checks, for each type of brick, which additional data is needed for the multi inputs
     * @example If the brick is a meta_ad, we may need the data of the meta_adset and meta_campaign, because we want
     * to render inputs conditionally based on the data of the meta_adset and meta_campaign.
     * @param brick The brick
     * @returns An object with the additional data
     */
    static getAdditionalBrickVars = (brick: Brick): IAdditionalVars => {
        const additionalVars: IAdditionalVars = {};

        const setup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');

        if (!setup) return additionalVars;

        // If the brick has additional data, we get that data via the BrickHelpers.getBrickParent function
        if (setup.additionalVars) {
            setup.additionalVars.forEach((additionalVar) => {
                const data = BrickHelpers.getBrickParent(brick, additionalVar);

                if (!data) return;

                additionalVars[additionalVar] = data;
            });
        }

        additionalVars.datasetId = BrickFeedHelpers.getDataSetIdOfParentFeedBrick(brick);

        additionalVars.parent = this.getBrickParents(brick);

        return additionalVars;
    };

    /**
     * Gets brick parent so we can parse values based on parent data
     * @param brick
     * @param allBricks
     * @returns Brick parents
     */
    private static getBrickParents = (brick: Brick): BrickParent | undefined => {
        if (!brick.parentId) return;

        let parent: BrickParent | undefined = BrickHelpers.getBrickById(brick.parentId);
        if (!parent) return;

        // Parse the values for the brick
        parent = this.parseAllDynamicValuesForBrick(parent, parent.id);

        if (parent?.parentId) parent.parent = this.getBrickParents(parent);

        return parent;
    };

    /**
     * Parse all dynamic values for single brick
     * @param obj
     * @param brickId
     * @returns brick with parsed values
     */
    private static parseAllDynamicValuesForBrick(obj: any, brickId: string) {
        if (Array.isArray(obj)) {
            // If the object is an array, recursively apply the function to each element
            return obj.map((item: any) => this.parseAllDynamicValuesForBrick(item, brickId));
        } else if (obj !== null && typeof obj === 'object') {
            // If the object is a dictionary (or object in JS terms), process its keys
            return Object.keys(obj).reduce((acc, key) => {
                const value = obj[key];
                acc[key] = typeof value === 'string' && value.startsWith('%[') && value.endsWith(']%') ? this.parseDynamicValue(brickId, value) : value;
                return acc;
            }, {});
        }
        // If it's neither an array nor an object, return it as is
        return obj;
    }

    /**
     * Parse dynamic value
     * @param brickId
     * @param value
     * @returns expected value
     */
    static parseDynamicValue = (brickId: string, value: string): string | undefined => {
        const brick = this.getBrickById(brickId);
        if (!brick) return undefined;

        // Checks if the value is dynamic
        if (!RegexHelpers.validate('dynamicValue', value)) return value;

        const additionalVars = this.getAdditionalBrickVars(brick);
        return DynamicDataHelper.getDynamicValue(value, { ...additionalVars, brick });
    };

    /** Get the title of the brick. */
    static getBrickTitle = (itemId: string, brick?: Brick): string => {
        if (!brick) return itemId.split('-')[1]; // If the brick is not found then means it is a creative format, so we need to get the title from the item id.

        return BrickHelpers.parseDynamicValue(brick.id, brick.title) || Translation.get('noTitle', 'bricks');
    };

    static getBrickStructure = (bricks: Brick[], result: Brick[] = []): Brick[] => {
        const brickMap = new Map(result.map((brick) => [brick.id, brick]));

        for (const brick of bricks) {
            const isCustomBrick = CustomBrickHelpers.isCustomBrick(brick);

            if (!brickMap.has(brick.id) && !isCustomBrick) {
                // Only add if not already added.
                brickMap.set(brick.id, brick);

                const children = BrickHelpers.getChildrenOfBrick(brick);
                if (children.length) {
                    const childrenStructure = BrickHelpers.getBrickStructure(children, Array.from(brickMap.values()));
                    for (const child of childrenStructure) {
                        brickMap.set(child.id, child);
                    }
                }
            }
        }

        return Array.from(brickMap.values()); // Convert back to an array
    };

    /**
     * The is function is used in order to create custom bricks that are not saved in the database.
     */
    static createCustomBrick = (brick: Brick, subType: BrickSubType, format: Format, data: Record<string, unknown>): Brick => {
        const clonedBrick: Brick = cloneDeep(brick);
        const id = this.getCustomBrickId(clonedBrick.id, format.key);
        const customData = { ...clonedBrick.data, ...data };
        delete customData?.settings?.formats; // Remove the formats from the custom brick

        return {
            ...clonedBrick,
            subType: subType,
            id,
            parentId: clonedBrick.id,
            title: format.title,
            type: BrickType.CHILD,
            order: undefined,
            data: customData,
            isCustomBrick: true
        };
    };

    /**
     * This function is used to get the custom brick id.
     */
    static getCustomBrickId = (brickId: string, title: string): string => {
        return `${brickId}-${title}`;
    };

    /**
     * Convert all found brick ids to bricks objects
     */
    static convertBrickIdsToBricks = (brickIds: string[]): Brick[] => {
        return brickIds.map((brickId) => BrickHelpers.getBrickById(brickId)).filter((brick) => !!brick);
    };
}

export default BrickHelpers;
