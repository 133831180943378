import { FilterSetup } from 'types/filter.type';
import { Tab } from 'components/ui-base/Tabbar/types/tab-bar-tab.type';
import { CreativeViewType } from 'components/creatives-v2/components/creative-overview/types/creative-overview.type';
import { Brick, BrickPublishStatus, AddBrickPayload, BrickSubType } from './brick.type';
import { CustomerConfig } from '../data/customerConfig';
import { BrickPublishJob } from './brick-publish.type';
import { ValidationResults } from './validator.type';

// Complete Product Builder ComponentStore
export interface BricksComponentStore {
    initialStateIsSet?: boolean;
    download?: DownloadState;

    publish: PublishJobs;

    /**
     * Determines whether the slide panel is open
     */
    slidePanelOpen: boolean;
    /**
     * Determines whether the comments component in the slide panel is open
     */
    slidePanelCommentsOpen: boolean;
    /**
     * Determines whether the slide panel with the general settings is open
     */
    slidePanelSettingsOpen: boolean;
    /**
     * The data of the snackbar that is visible after you added a brick
     */
    addBrickSnackbar: CSAddBrickSnackbar;
    /**
     * The data of the publish execution panel
     */
    publishExecutionPanel: CSPublishExecutionPanel;
    /**
     * The resource for the customer config
     */
    resourceConfig?: string;
    /**
     * The tab that is currently active in the content view
     */
    activeTab?: UnifiedTab;
    /**
     * A list of brick ids that are checked
     */
    checkedBricks?: CheckedBricks;
    /**
     * A list of bricks id that are expanded
     */
    expandedBricks?: ExpandedBricks;
    /**
     * The active main tab
     */
    activeCampaignTab?: string;
    /**
     * The original data of the active brick. This is saved to compare it with the new changes later
     */
    originalBrick?: Brick;
    /**
     * The validation errors per brick
     */
    validationErrors?: ValidationResults;
    /**
     * Filters
     */
    filters?: Filters;
    /**
     * Filter setup
     */
    filterSetup?: FilterSetup;
    /**
     * Visible columns in the table
     */
    activeColumns: ActiveColumns;
    /**
     * The customer config with the overwrites for this campaign format
     */
    campaignFormatConfig: CustomerConfig;
    /**
     * If true, we have checked whether there is a campaign format specific customer config and that config is successfully loaded in the store
     */
    campaignFormatConfigLoaded: boolean;
    /**
     * The current asset upload status
     */
    assetUpload?: AssetUpload;
    /**
     * The list of current bricks.
     */
    bricks?: BricksObject;
    /**
     * All the bricks that has been modified or added.
     */
    updatedBricks?: BricksObject;
    /**
     * The temporary brick that is used for the add brick dialog.
     */
    temporaryBrick?: AddBrickPayload;
    /**
     * Publish dialog store.
     */
    publishDialog?: PublishDialog;
    /**
     * Used to scroll to a specific brick.
     */
    brickIdToScrollTo?: string;
    /**
     * This is used to keep track of the current creative view and the single view key outside of the creative overview component.
     */
    creative?: { view: CreativeViewType; singleViewKey?: string; hideSingleViewSideBar?: boolean };
    /**
     * Current main view that is being showed. For example table or compact view.
     */
    view: ViewType;
    /**
     * Feed process
     */
    feedState?: FeedState;
    /**
     * Pagination state
     */
    pagination?: PaginationState;
    /**
     * This is needed in order to know if the custom bricks should be re-added.
     */
    reRunCustomBricks?: number;
    /*
     * The last added brick id, this is used in order to scroll to the last added brick.
     */
    lastAddedBrickId?: string;
}

export interface BrickPagination {
    maxResultsReached?: boolean;
    page?: number;
}

export interface PaginationState {
    [key: string]: BrickPagination;
}

export interface FeedState {
    [id: string]: BrickFeedProcess;
}

export enum FeedProcessStatus {
    STARTING = 'starting',
    PROCESSING = 'processing',
    DONE = 'done'
}

export interface BrickFeedProcess {
    status: FeedProcessStatus;
    totalRows?: number;
    processedRows: number;
}

interface AssetUpload {
    /**
     * Determines whether the asset upload is processing in the background
     */
    isLoading: boolean;
}

export interface CSAddBrickSnackbar {
    /**
     * Determines whether the snackbar is visible or not
     */
    open: boolean;
    /**
     * The brick that is added
     */
    bricks?: Brick[];
}

export interface CSPublishExecutionPanelJob {
    publishId: string;
    brickIds: string[];
}
export interface CSPublishExecutionPanel {
    jobs: CSPublishExecutionPanelJob[];
    open: boolean;
}

export type AddBrickDialogView = 'overview' | 'briefing';
export type ViewType = 'table' | 'compact';

export interface BrickTab extends Tab {
    subTypes?: BrickSubType[];
    brickTab: boolean;
    afterTab?: string;
    hideTabs?: string[];
    items?: any[];
}

/*
 * A unified tab type that combines both the default Tab and the BrickTab
 */
export type UnifiedTab = Tab | BrickTab;

/**
 * Brick filters
 */
export interface Filters {
    subType?: BrickSubType[];
    status?: string[];
    publishStatus?: BrickPublishStatus[];
    assignee?: string[];
    reviewer?: string[];
    searchTerm?: string;
    deadline?: string;
    startDate?: string;
    endDate?: string;
    topBrickIds?: string[];
}

/**
 * Active columns in table
 */
export type ActiveColumns = {
    [key: string]: ActiveColumn[];
};

export interface ActiveColumn {
    identifier: string;
    order?: number;
}

/**
 * A list of mapped bricks with their id as key.
 * The id has a prefix of i_
 */
export interface BricksObject {
    [id: string]: Brick;
}

export interface DownloadState {
    // publish ID
    [key: string]: boolean;
}

export interface PublishJobs {
    // publish ID
    [key: string]: CSBrickPublishJob;
}

export interface CSBrickPublishJob {
    publishId: string;
    downloadUrl?: string;
    bricks: {
        [key: string]: BrickPublishJob;
    };
}

export interface ExpandedBricks {
    [key: string]: boolean;
}

export interface CheckedBricks {
    [key: string]: boolean;
}

export interface PublishDialog {
    activePreviewId?: string;
    checkedBricks?: CheckedBricks;
    expandedBricks?: ExpandedBricks;
}
