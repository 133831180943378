import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { Brick, BrickSetup } from '../types/brick.type';
import { useBricksNavigation } from './useBricksNavigation';
import BrickHelpers from '../helpers/brick.helpers';

type ShowAddBricksNotificationFn = (addedBricks: Brick[] | undefined) => void;

// This hook returns a function you can call to show a notification after you added bricks
const useAddBricksNotification = (): ShowAddBricksNotificationFn => {
    const { navigateToItem } = useBricksNavigation();

    const getAddBricksMessage = (bricks: Brick[]): string => {
        if (bricks.length === 1) {
            const setup: BrickSetup = BrickHelpers.getBrickData(bricks[0].subType, 'setup');
            return `${Translation.get('feedback.success.successfullyAddedBrick', 'bricks')} ${setup.title}`;
        }

        return Translation.get('feedback.success.successfullyAddedMultipleItems', 'bricks');
    };

    const showAddBricksNotification: ShowAddBricksNotificationFn = (addedBricks) => {
        if (!addedBricks?.length) return;

        SnackbarUtils.success(getAddBricksMessage(addedBricks), {
            primaryAction: {
                children: Translation.get('viewDetails', 'bricks'),
                variant: 'contained',
                onClick: () => {
                    navigateToItem(addedBricks[0].id);
                }
            }
        });
    };

    return showAddBricksNotification;
};

export { useAddBricksNotification };
