import { ExpandedState } from '@tanstack/react-table';
import ComponentStore from 'components/data/ComponentStore';
import { BrickViewState } from './brick-view-state.helpers';
import { Brick } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import { BricksObject } from '../types/bricksComponentStore.type';

/** Maximum number of children a brick can have for auto expand on init. */
const MAX_NUMBER_OF_CHILDREN = 15;
class BrickExpandedRowsHelper {
    static setExpandedBrick = (isExpanded: boolean, brickId?: string): void => {
        if (!brickId) return;

        ComponentStore.setModel('Bricks', `expandedBricks.${brickId}`, isExpanded); // Set the expanded state of the brick to the component store.
        const expandedBricks = BrickViewState.get('expandedBricks');
        BrickViewState.set('expandedBricks', { ...expandedBricks, [brickId]: isExpanded }); // Set the expanded state of the brick to the local store.
    };

    static removeExpandedBrick = (brickId?: string): void => {
        if (!brickId) return;

        ComponentStore.removeItem('Bricks', `expandedBricks.${brickId}`); // Remove the expanded state of the brick from the component store.
        const expandedBricks = BrickViewState.get('expandedBricks');

        if (!expandedBricks) return;

        const { [brickId]: _, ...updatedExpandedBricks } = expandedBricks;
        BrickViewState.set('expandedBricks', updatedExpandedBricks); // Remove the expanded state of the brick from the local store.
    };

    static expandedAllBricks = (bricks: Brick[]): ExpandedState => {
        const initialValue: ExpandedState = {};
        return bricks.reduce((acc, brick) => {
            acc[brick.id] = true;
            return acc;
        }, initialValue);
    };

    /**
     * Collapse all expanded bricks that have more than 15 children.
     */
    static collapseLargeBricks = (): void => {
        const expandedBricks = BrickViewState.get('expandedBricks');
        const bricks: BricksObject | undefined = ComponentStore.get('Bricks')?.bricks;

        // No need to check if the bricks should be collapsed if there are no bricks or expanded bricks.
        if (!expandedBricks || !bricks) return;

        Object.keys(expandedBricks).forEach((brickId) => {
            const brickIdWithPrefix = BrickHelpers.getBrickIdPrefix(brickId);
            const brick = bricks?.[brickIdWithPrefix];

            if (!brick) return;

            const numberOfChildren = BrickHelpers.getChildrenOfBrick(brick).length;

            if (numberOfChildren > MAX_NUMBER_OF_CHILDREN && expandedBricks[brickId]) {
                BrickExpandedRowsHelper.setExpandedBrick(false, brickId);
            }
        });
    };
}

export default BrickExpandedRowsHelper;
